import RestService from '@/services/RestService'
import axios from 'axios'
import utility from '@/common/utility'
import auth from '@/common/auth'

let resourceName = 'g23'

let batchAddUrl = 'batchAdd'

class G23Service extends RestService {
  batchAdd (g23Models) {
    g23Models.forEach(g23Model => {
      g23Model.generatePrimaryKey()
    })
    return axios.post(utility.getRestFullUrl(this.resourceName, batchAddUrl), g23Models, {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }
}

const g23Service = new G23Service(resourceName)

export default g23Service
