import RestService from '@/services/RestService'
import axios from 'axios'
import utility from '@/common/utility'
import auth from '@/common/auth'

let resourceName = 'prepareMaterialList'
let treeListUrl = 'treeList'

class PrepareMaterialListService extends RestService {
  treeList (tenderGuid) {
    return axios.get(utility.getRestFullUrl(this.resourceName, treeListUrl), {
      params: {
        tenderGuid
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  projectList (projectGuid, engineeringType) {
    return axios.get(utility.getRestFullUrl(this.resourceName, 'projectList'), {
      params: {
        projectGuid,
        engineeringType
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }
}

const prepareMaterialListService = new PrepareMaterialListService(resourceName)

export default prepareMaterialListService
