<template>
  <div class="g25">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-button type="primary" @click="handleSaveClick">保存</el-button>
          <el-button v-if="errorKeys.length" type="primary" @click="handleErrorClick">下一错误</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>
    <div ref="spreadContainer" class="spread-container">
    </div>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import SpreadMixin from '@/mixins/SpreadMixin'
import auth from '@/common/auth'
import prepareMaterialListService from '@/services/prepareMaterialListService'
import g23Service from '@/services/g23Service'
import g25Service from '@/services/g25Service'
import G25SpreadInfo from '@/views/PlanStatManage/HeaderColumns/G25SpreadInfo'
import tenderService from '@/services/tenderService'
import G25Model from '@/model/G25Model'
import utility from '@/common/utility'
import { mapGetters } from 'vuex'
import config from '@/config'

export default {
  name: 'g25',
  mixins: [
    SpreadMixin
  ],
  components: {
    MySearchBar
  },
  data () {
    return {
      materialList: [],
      materialListMap: {},
      g25List: [],
      spreadInfo: G25SpreadInfo,
      currentErrorIndex: 0,
      startYear: 2020
    }
  },
  computed: {
    errorKeys () {
      return Object.keys(this.errorMap)
    },
    tenderGuid () {
      return auth.getUserInfo().tenderGuid
    },
    ...mapGetters('planStatExamination', {
      planStatExaminationGuid: 'getPlanStatExaminationGuid',
      tenderGuid: 'getTenderGuid',
      tenderName: 'getTenderName',
      year: 'getYear',
      approvalUserGuid: 'getApprovalUserGuid'
    })
  },
  methods: {
    handleSaveClick () {
      // 检查是否有错误
      if (Object.keys(this.errorMap).length) {
        this.$message({
          type: 'error',
          message: '数据存在问题，请修正后再保存！'
        })
        return
      }
      this.$myLoading()
      const result = this.translate()
      if (!result) {
        this.$alert('请检查您的输入！')
        this.$loading().close()
        return
      }
      this.pushData()
    },
    handleErrorClick () {
      let rowIndex = this.errorMap[this.errorKeys[this.currentErrorIndex]].rowIndex
      this.worksheet.showRow(rowIndex, GC.Spread.Sheets.VerticalPosition.top)
      this.currentErrorIndex = (this.currentErrorIndex + 1) % this.errorKeys.length
    },
    setProtection () {
      this.worksheet.options.isProtected = true
      let unLockStyle = new GC.Spread.Sheets.Style()
      unLockStyle.backColor = '#aea'
      unLockStyle.locked = false

      let start = 6
      let len = 11
      for (let i = 0; i < len; ++i) {
        this.worksheet.setStyle(-1, start + i, unLockStyle)
      }
      if (this.year !== 2020) {
        this.worksheet.setStyle(-1, 5, unLockStyle)
      }
    },
    bindCellChanged () {
      this.worksheet.bind(GC.Spread.Sheets.Events.ValueChanged, (sender, info) => {
        this.rowCompute(info.row)
      })
    },
    bindDragFillBlockCompleted () {
      this.worksheet.bind(GC.Spread.Sheets.Events.DragFillBlockCompleted, (e, info) => {
        for (let i = 0; i < info.fillRange.rowCount; ++i) {
          this.rowCompute(info.fillRange.row + i)
        }
      })
    },
    bindClipboardPasted () {
      this.worksheet.bind(GC.Spread.Sheets.Events.ClipboardPasted, (sender, info) => {
        for (let i = 0; i < info.cellRange.rowCount; ++i) {
          this.rowCompute(i + info.cellRange.row)
        }
      })
    },
    rowCompute (rowIndex) {
      let currentYearNum = this.worksheet.getValue(rowIndex, 5)
      let materialListNum = parseFloat(this.worksheet.getValue(rowIndex, 4))

      let flag = false

      let months = {}
      months.month1 = this.worksheet.getValue(rowIndex, 6)
      months.month2 = this.worksheet.getValue(rowIndex, 7)
      months.month3 = this.worksheet.getValue(rowIndex, 8)
      months.month4 = this.worksheet.getValue(rowIndex, 9)
      months.month5 = this.worksheet.getValue(rowIndex, 10)
      months.month6 = this.worksheet.getValue(rowIndex, 11)
      months.month7 = this.worksheet.getValue(rowIndex, 12)
      months.month8 = this.worksheet.getValue(rowIndex, 13)
      months.month9 = this.worksheet.getValue(rowIndex, 14)
      months.month10 = this.worksheet.getValue(rowIndex, 15)
      months.month11 = this.worksheet.getValue(rowIndex, 16)
      months.month12 = this.worksheet.getValue(rowIndex, 17)

      for (let i = 1; i < 12; ++i) {
        if (months[`month${i}`] && months[`month${i}`].toString().length) {
          flag = true
        }
      }

      if (utility.isNumber(months.month1) &&
        utility.isNumber(months.month2) &&
        utility.isNumber(months.month3) &&
        utility.isNumber(months.month4) &&
        utility.isNumber(months.month5) &&
        utility.isNumber(months.month6) &&
        utility.isNumber(months.month7) &&
        utility.isNumber(months.month8) &&
        utility.isNumber(months.month9) &&
        utility.isNumber(months.month10) &&
        utility.isNumber(months.month11)
      ) {
        let sum = 0
        for (let i = 1; i < 12; ++i) {
          sum = utility.floatAdd(sum, months[`month${i}`])
        }
        months.month12 = parseFloat(utility.floatSubstract(currentYearNum, sum))
        this.worksheet.setValue(rowIndex, 17, months.month12)
      } else {
        this.worksheet.setValue(rowIndex, 17, '')
      }

      if (flag && (!utility.isNumber(currentYearNum) || currentYearNum > materialListNum)) {
        this.setError('请检查数据是否填写正确', rowIndex, 17)
      } else {
        this.setError(null, rowIndex, 17)
      }

      if (flag && (!utility.isNumber(months.month12) || months.month12 < 0)) {
        this.setError('请检查数据是否填写正确', rowIndex, 17)
      } else {
        this.setError(null, rowIndex, 17)
      }
      this.currentErrorIndex = 0
    },
    translate () {
      let dataTable = this.spread.toJSON({
        ignoreStyle: true
      }).sheets[this.spreadInfo.sheetName].data.dataTable
      console.log(dataTable)

      this.g25List = []
      let result = true
      for (let key in Object.keys(dataTable)) {
        let item = dataTable[key]

        let flag = true
        for (let i = 6; i < 18; ++i) {
          if (item[i] === undefined || item[i] === null) {
            flag = false
          }
        }
        if (!flag) {
          continue
        }

        let g25Model = new G25Model({ planStatExaminationGuid: this.planStatExaminationGuid, materialListGuid: this.materialListMap[parseInt(key)].materialListGuid, tenderGuid: auth.getUserInfo().tenderGuid, year: this.year })
        g25Model.currentYearNum = parseFloat(item[5].value)
        g25Model.month1 = parseFloat(item[6].value)
        g25Model.month2 = parseFloat(item[7].value)
        g25Model.month3 = parseFloat(item[8].value)
        g25Model.month4 = parseFloat(item[9].value)
        g25Model.month5 = parseFloat(item[10].value)
        g25Model.month6 = parseFloat(item[11].value)
        g25Model.month7 = parseFloat(item[12].value)
        g25Model.month8 = parseFloat(item[13].value)
        g25Model.month9 = parseFloat(item[14].value)
        g25Model.month10 = parseFloat(item[15].value)
        g25Model.month11 = parseFloat(item[16].value)
        g25Model.month12 = parseFloat(item[17].value)
        g25Model.init()

        let sum = 0
        for (let i = 1; i < 13; ++i) {
          if (g25Model[`month${i}`] < 0) {
            result = false
          }
          sum = utility.floatAdd(sum, g25Model[`month${i}`])
        }

        if (sum !== g25Model.currentYearNum) {
          result = false
        }

        if (utility.isNumber(sum) && sum === g25Model.currentYearNum && sum !== 0) {
          this.g25List.push(g25Model)
        }
      }
      return result
    },
    pushData () {
      g25Service.batchAdd(this.g25List)
        .then(res => {
          console.log(res)
          this.$loading().close()
          if (res.data.code === 1) {
            this.$message({
              type: 'success',
              message: '操作成功！'
            })
            this.$router.push('/g25Index')
          } else {
            if (res.data.data.length) {
              res.data.data.forEach(item => {
                this.setError('请检查数据是否填写正确', this.materialListMap[item.materialListGuid].index, 10)
              })
            }
          }
        })
        .catch(err => {
          console.log(err)
          this.$loading().close()
          this.$message({
            type: 'error',
            message: '操作失败！'
          })
        })
    },
    bindMaterialList (defaultData) {
      console.log(defaultData)
      // 首先处理defaultData
      let defaultDataMap = {}
      if (defaultData.length) {
        defaultData.forEach(item => {
          defaultDataMap[item.materialListGuid] = item
        })
      }
      // 设定表格的行数
      this.worksheet.setRowCount(this.materialList.length)
      let dataArray = []
      // 绑定数据
      this.yearNum = this.startYear - parseInt(config.projectStartYear[this.projectGuid] || this.startYear)
      this.materialList.forEach((item, index) => {
        let rowData = []
        rowData.push(index + 1)
        rowData.push(item.parentMaterialList.materialListName)
        rowData.push(item.materialListName)
        rowData.push(item.unit)
        rowData.push(item.num)
        if (defaultDataMap.hasOwnProperty(item.materialListGuid)) {
          switch (this.yearNum) {
            case 0:
              rowData.push(defaultDataMap[item.materialListGuid].firstYearNum)
              break
            case 1:
              rowData.push(defaultDataMap[item.materialListGuid].secondYearNum)
              break
            case 2:
              rowData.push(defaultDataMap[item.materialListGuid].thirdYearNum)
              break
          }
        } else {
          rowData.push('')
        }
        for (let i = 0; i < 12; ++i) {
          rowData.push('')
        }
        dataArray.push(rowData)

        // 附带算一下materialListMap
        item.index = index
        this.materialListMap[index] = item
        this.materialListMap[item.materialListGuid] = item
      })
      this.worksheet.setArray(0, 0, dataArray)
    },
    getTender () {
      tenderService.get(this.tenderGuid)
        .then(res => {
          if (res.data.code === 1) {
            this.startYear = parseInt(res.data.data[0].startDate.substring(0, 4))
            this.projectGuid = res.data.data[0].projectGuid
            this.getG25List()
          }
        })
    },
    getG25List () {
      g25Service.list({ tenderGuid: this.tenderGuid, planStatExaminationGuid: this.planStatExaminationGuid })
        .then(res => {
          this.getMaterialList(res.data.data.map(item => item.materialListGuid))
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '数据加载失败！'
          })
        })
    },
    getMaterialList (exceptData) {
      if (!exceptData) {
        exceptData = []
      }
      prepareMaterialListService.list({}, { tenderGuid: auth.getUserInfo().tenderGuid })
        .then(res => {
          if (res.data.code === 1) {
            this.materialList = res.data.data.filter(item => {
              if (item.num === 0) {
                return false
              }
              if (exceptData.indexOf(item.materialListGuid) !== -1) {
                return false
              }
              return true
            })

            // TODO
            if (this.year === this.startYear) {
              this.getDefaultData()
            } else {
              this.bindMaterialList([])
              this.setProtection()
            }
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getDefaultData () {
      g23Service.list({ tenderGuid: this.tenderGuid })
        .then(res => {
          this.bindMaterialList(res.data.data)
          this.setProtection()
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '数据加载失败！'
          })
        })
    }
  },
  mounted () {
    this.initWorkbook(this.$refs.spreadContainer)
    this.getTender()
    this.bindCellChanged()
    this.bindClipboardPasted()
    this.bindDragFillBlockCompleted()
  }
}
</script>

<style scoped lang="scss">
.g25 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .spread-container {
    flex: 1;
  }
}
</style>
