import spreadMenusJson from '@/assets/json/spreadMenus'

export default {
  data () {
    return {
      errorMap: {},
      spreadMenus: spreadMenusJson.filter(menu => !menu.disabled)
    }
  },
  methods: {
    initWorkbook (el) {
      // 初始化spread，目前只开一个sheet页
      this.spread = new GC.Spread.Sheets.Workbook(el, { calcOnDemand: true, sheetCount: 0 })
      // 设置右键菜单不可以使用
      this.spread.options.allowContextMenu = false
      // 设置右键菜单可用项目
      this.spread.contextMenu.menuData = this.spreadMenus
      // 设置不可粘贴excel样式
      this.spread.options.allowCopyPasteExcelStyle = false
      // 设置不可新增sheet页
      this.spread.options.newTabVisible = false

      this.spread.commandManager().setShortcutKey('clearAndEditing', GC.Spread.Commands.Key.del, false, false, false, false)

      // 初始化sheets页面
      this.initWorksheets()
    },
    initWorksheets () {
      // 添加worksheet页
      this.worksheet = new GC.Spread.Sheets.Worksheet(this.spreadInfo.sheetName)
      this.spread.addSheet(0, this.worksheet)

      // 设置剪贴板模式
      this.worksheet.options.clipBoardOptions = 1

      // 初始化列头
      this.initWorksheetColumns()
    },
    initWorksheetColumns () {
      this.spread.suspendPaint()

      if (this.spreadInfo.headerColumns && this.spreadInfo.headerColumns.length) {
        // 设置头部有多少行
        this.worksheet.setRowCount(this.spreadInfo.headerRowCount, GC.Spread.Sheets.SheetArea.colHeader)
        // 设置共有多少列
        this.worksheet.setColumnCount(this.spreadInfo.headerColumnCount, GC.Spread.Sheets.SheetArea.colHeader)
        // 初始化每列数据
        this.spreadInfo.headerColumns.forEach(column => {
          if (column.children && column.children.length) {
            // 有子列，所以需要合并列，并且给子列赋值
            this.worksheet.addSpan(0, column.index, 1, column.children.length, GC.Spread.Sheets.SheetArea.colHeader)
            column.children.forEach(childColumn => {
              this.worksheet.setValue(1, childColumn.index, childColumn.name, GC.Spread.Sheets.SheetArea.colHeader)
              this.worksheet.setColumnWidth(childColumn.index, childColumn.width)
            })
          } else {
            // 没有子列，所以需要合并行
            this.worksheet.addSpan(0, column.index, this.spreadInfo.headerRowCount, 1, GC.Spread.Sheets.SheetArea.colHeader)
            this.worksheet.setColumnWidth(column.index, column.width)
          }
          this.worksheet.setValue(0, column.index, column.name, GC.Spread.Sheets.SheetArea.colHeader)
        })
      }

      this.spread.resumePaint()
    },
    setError (error, rowIndex, colIndex) {
      let cellKey = `${rowIndex}_${colIndex}`
      if (error !== null) {
        // 如果当前单元格验证未通过，则加入到errorMap中
        this.$set(this.errorMap, cellKey, {
          rowIndex,
          colIndex,
          error
        })
        // 设置验证失败样式
        this.worksheet.getCell(rowIndex, colIndex).setBorder(new GC.Spread.Sheets.LineBorder('red', GC.Spread.Sheets.LineStyle.hair), { all: true }, 3)
        this.worksheet.getCell(rowIndex, colIndex).backColor('#ccc')
      } else {
        // 如果单元格当前验证没有问题，则从errorMap中删除
        if (this.errorMap.hasOwnProperty(cellKey)) {
          this.$delete(this.errorMap, cellKey)
        }
        this.worksheet.getCell(rowIndex, colIndex).setBorder(new GC.Spread.Sheets.LineBorder('#d4d4d4', GC.Spread.Sheets.LineStyle.thin), { all: true }, 3)
        this.worksheet.getCell(rowIndex, colIndex).backColor('white')
      }
    }
  }
}
